// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-archive-jsx": () => import("./../../../src/pages/archive.jsx" /* webpackChunkName: "component---src-pages-archive-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-donate-jsx": () => import("./../../../src/pages/donate.jsx" /* webpackChunkName: "component---src-pages-donate-jsx" */),
  "component---src-pages-donors-jsx": () => import("./../../../src/pages/donors.jsx" /* webpackChunkName: "component---src-pages-donors-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-single-event-jsx": () => import("./../../../src/templates/single-event.jsx" /* webpackChunkName: "component---src-templates-single-event-jsx" */)
}

